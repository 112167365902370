// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import Person from '@material-ui/icons/Person';
import Assignment from '@material-ui/icons/Assignment';
// core components/views for Admin layout

import AdminPage from '../pages/Admin';
import DashboardPage from '../pages/Dashboard';
import AdministradoraPage from '../pages/Administradora';
import ImovelPage from '../pages/Imovel';
import AuditoriaPage from '../pages/Auditoria';
import ArquivosPage from '../pages/Arquivos';
import DespesasPage from '../pages/Despesas';
import DespesasLancadasPage from '../pages/DespesasLancadas';

const menuRoutes = [
  {
    path: '/painel',
    name: 'Painel',
    icon: Dashboard,
    component: DashboardPage,
    layout: '/admin'
  },
  {
    path: '/administradores',
    name: 'Usuários Administradores',
    icon: Person,
    component: AdminPage,
    layout: '/admin',
  }, 
  {
    path: '/administradora',
    name: 'Administradoras',
    icon: Assignment,
    component: AdministradoraPage,
    layout: '/admin',
  },
  {
    path: '/imovel',
    name: 'Imoveis',
    icon: Assignment,
    component: ImovelPage,
    layout: '/admin',
  },
  {
    path: '/auditoria',
    name: 'Auditoria',
    icon: Assignment,
    component: AuditoriaPage,
    layout: '/admin',
  },
  {
    path: '/arquivos',
    name: 'Envio de arquivos',
    icon: Assignment,
    component: ArquivosPage,
    layout: '/admin',
  },
  {
    path: '/despesas',
    name: 'Despesas Resumo',
    icon: Assignment,
    component: DespesasPage,
    layout: '/admin',
  },
  {
    path: '/despesaslancadas',
    name: 'Despesas Lançadas',
    icon: Assignment,
    component: DespesasLancadasPage,
    layout: '/admin',
  },


];

export default menuRoutes;
