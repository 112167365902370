import React, { useState, useEffect, useCallback } from 'react';
import { useToasts } from 'react-toast-notifications'
import moment from 'moment';
import ptBrLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from '@material-ui/core/styles';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Table from 'components/Table/Table.js';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";


import api from '../../services/api';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [dataGeral, setDataGeral] = useState([]);
  const [dataCondominios, setDataCondominios] = useState([]);
  const [dataIptu, setDataIptu] = useState([]);
  const [dataLaudemio, setDataLaudemio] = useState([]);
  const [dataFunesbom, setDataFunesbom] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date('2021-08-05'));

  const { addToast } = useToasts();

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.post(`/dashboard`, {
        selectedDate
      });


      const dataCondominios = [{       
        imoveis_condominio: String(response.data.condominio.com_condominio),
        imoveis_sem_condominio: String(response.data.condominio.sem_condominio),
        boletos_capturados:  String(response.data.condominio.boletos_capturados),
        boletos_faltantes: String(response.data.condominio.boletos_faltantes),
      }];
      setDataCondominios(dataCondominios);

      const dataIptu = [{
        imoveis_iptu: String(response.data.iptu.com_iptu),
        imoveis_sem_iptu: String(response.data.iptu.sem_iptu),
        boletos_capturados: String(response.data.iptu.boletos_capturados),
        boletos_faltantes: String(response.data.iptu.boletos_faltantes),

      }];

      setDataIptu(dataIptu);


      const dataFunesbom = [{
        imoveis_funesbom: String(response.data.funesbom.com_funesbom),
        imoveis_sem_funesbom: String(response.data.funesbom.sem_funesbom),
        boletos_capturados: String(response.data.funesbom.boletos_capturados),
        boletos_faltantes:String(response.data.funesbom.boletos_faltantes),

      }];

      setDataFunesbom(dataFunesbom);

      setLoading(false);

    }
    catch (e) {

      setLoading(false);
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [selectedDate]);

  useEffect(() => {
    getData();
  }, [selectedDate]);




  return (
    <div>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Visão Geral dos Imóveis - {moment(selectedDate).format("M/Y")}</h4>
            </CardHeader>
            <CardBody>

              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
                <DatePicker
                  views={["year", "month"]}
                  label="Selecione Mes / Ano"
                  value={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                />
              </MuiPickersUtilsProvider>

              {!loading ? <>

                <br /><br />
                <h4 style={{ backgroundColor: '#999', padding: 12, color: '#FFF', fontWeight: 'bold' }}>Condomínio</h4>
                <Table
                  tableHeaderColor="primary"
                  tableHead={['Imoveis com condominio', 'Imoveis sem condominio', 'Boletos capturados', 'Boletos faltantes']}
                  tableColumn={['imoveis_condominio', 'imoveis_sem_condominio', 'boletos_capturados', 'boletos_faltantes']}
                  tableColumnSize={['15%', '15%', '15%', '15%']}
                  tableData={dataCondominios}
                />


                <h4 style={{ backgroundColor: '#999', padding: 12, color: '#FFF', fontWeight: 'bold' }}>Iptu</h4>
                <Table
                  tableHeaderColor="primary"
                  tableHead={['Imoveis com iptu', 'Imoveis sem iptu', 'Boletos capturados', 'Boletos faltantes']}
                  tableColumn={['imoveis_iptu', 'imoveis_sem_iptu', 'boletos_capturados', 'boletos_faltantes']}
                  tableColumnSize={['15%', '15%', '15%', '15%']}
                  tableData={dataIptu}
                />
               

                <h4 style={{ backgroundColor: '#999', padding: 12, color: '#FFF', fontWeight: 'bold' }}>Funesbom {moment(selectedDate).format("Y")}</h4>
                <Table
                  tableHeaderColor="primary"
                  tableHead={['Imoveis com funesbom', 'Imoveis sem funesbom', 'Boletos capturados', 'Boletos faltantes']}
                  tableColumn={['imoveis_funesbom', 'imoveis_sem_funesbom', 'boletos_capturados', 'boletos_faltantes']}
                  tableColumnSize={['15%', '15%', '15%', '15%']}
                  tableData={dataFunesbom}
                />

              </> : <Box mt={6}>
                <CircularProgress />
              </Box>}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

    </div>
  );
}
