import React, { useEffect, useCallback, useState, useRef } from 'react';
import * as Yup from 'yup';
import InputMask from "react-input-mask";
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';


import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Selection from 'components/Selection/Selection.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditImovel() {
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState('create');

  const [codigo, setCodigo] = useState('');
  const [codigoControle, setCodigoControle] = useState('');
  const [descricao, setDescricao] = useState('');
  const [email, setEmail] = useState('');
  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [enderecoBoleto, setEnderecoBoleto] = useState('');
  const [cpfCnpjSacadoCondominio, setCpfCnpjSacadoCondominio] = useState('');
  const [iptuInscricao, setIptuInscricao] = useState('');
  const [cpfCnpjSacadoIptu, setCpfCnpjSacadoIptu] = useState('');
  const [funesbomInscricao, setFunesbomInscricao] = useState('');
  const [laudemioInscricao, setLaudemioInscricao] = useState('');

  const [tipoDocCondominio, setTipoDocCondominio] = useState('CPF');
  const [tipoDocIptu, setTipoDocIptu] = useState('CPF');

  const [administradora, setAdministradora] = useState();
  const [condominioQuempg, setCondominioQuempg] = useState();
  const [iptuQuempg, setIptuQuempg] = useState();
  const [iptuFormapg, setIptuFormapg] = useState();


  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();
  const classes = useStyles();

  useEffect(() => {

    if (id != "new") {
      setAction("update");
    }
  }, []);

  useEffect(() => {
    if (action === "update") {
      loadData(id);
    }
  }, [action]);

  const handleSave = (async (e) => {

    try {

      e.preventDefault();


      const schema = Yup.object().shape({
        codigo: Yup.string().required('Código é obrigatório !'),
        codigoControle: Yup.string().required('Código de controle é obrigatório !'),
        descricao: Yup.string().required('Descrição é obrigatória !'),
        //email: Yup.string().email('E-mail inválido').required('Email é obrigatório !'),
        condominioQuempg: Yup.string().required('Informe quem paga o condominio !'),
        administradora: Yup.string().required('Informe a administradora !'),
        //login: Yup.string().required('Informe o login!'),
        //senha: Yup.string().required('Informe a senha!'),
        enderecoBoleto: Yup.string().required('Informe o endereço do boleto!'),
        cpfCnpjSacadoCondominio: Yup.string().required('Informe o CPF/CNPJ do sacado no boleto de condominio !'),
        //iptuQuempg: Yup.string().required('Informe quem paga o IPTU do condominio !'),
        //iptuFormapg: Yup.string().required('Informe a forma de pagamneto do IPTU !'),
        //iptuInscricao: Yup.string().required('Informe a inscrição do IPTU !'),
        //cpfcnpjSacadoIptu: Yup.string().required('Informe o CPF/CNPJ do sacado no boleto de iptu !')

      });

      await schema.validate({ codigo, codigoControle, descricao, condominioQuempg, administradora, login, senha, enderecoBoleto, cpfCnpjSacadoCondominio, iptuQuempg, iptuFormapg, iptuInscricao, cpfCnpjSacadoIptu, email }, {
        abortEarly: false,
      });

      const postData = {
        codigo,
        codigo_controle: codigoControle,
        descricao,
        administradora,
        login,
        senha,
        endereco_boleto: enderecoBoleto,
        condominio_quempg: condominioQuempg,
        cpf_cnpj_sacado_condominio: cpfCnpjSacadoCondominio,
        iptu_quempg: iptuQuempg,
        iptu_formapg: iptuFormapg,
        iptu_inscricao: iptuInscricao,
        cpf_cnpj_sacado_iptu: cpfCnpjSacadoIptu,
        funesbom_inscricao: funesbomInscricao,
        laudemio_inscricao: laudemioInscricao,
        tipo_doc_condominio: tipoDocCondominio,
        tipo_doc_iptu: tipoDocIptu,
        email
      };

      if (action === 'update') {
        postData.id = id;
      }

      await api.post("/imovel", postData);

      history.push('/admin/imovel');

    }
    catch (e) {

      setLoading(false);

      console.log(e.message);

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }


      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });



    }
  });

  const loadData = useCallback(async () => {
    try {
      const response = await api.get(`/imovel/${id}`);
      setCodigo(response.data.codigo);
      setCodigoControle(response.data.codigo_controle);
      setDescricao(response.data.descricao);
      setLogin(response.data.login);
      setSenha(response.data.senha);
      setEmail(response.data.email);
      setEnderecoBoleto(response.data.enderecoBoleto);
      setTipoDocCondominio(response.data.tipo_doc_condominio);
      setTipoDocIptu(response.data.tipo_doc_iptu);
      setCondominioQuempg(response.data.condominio_quempg?.id);
      setAdministradora(response.data.administradora?.id);
      setEnderecoBoleto(response.data.endereco_boleto);
      setCpfCnpjSacadoCondominio(response.data.cpf_cnpj_sacado_condominio);
      setCpfCnpjSacadoIptu(response.data.cpf_cnpj_sacado_iptu);
      setIptuQuempg(response.data.iptu_quempg?.id);
      setIptuFormapg(response.data.iptu_formapg?.id);
      setIptuInscricao(response.data.iptu_inscricao);
      setFunesbomInscricao(response.data.funesbom_inscricao);
      setLaudemioInscricao(response.data.laudemio_inscricao);
    }
    catch (e) {

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });

      history.push("/admin/imovel");
    }
  });

  useEffect(() => {
    const aux = codigo.split('/');
    if (aux && aux.length > 0) {
      setCodigoControle(aux[0]);
    }
  }, [codigo]);


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} ref={formRef} onSubmit={handleSave}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Dados do imóvel / contrato</h4>
                <p className={classes.cardCategoryWhite}>Complete os dados.</p>
              </CardHeader>
              <CardBody>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="codigo"
                      label="Código"
                      name="codigo"
                      value={codigo}
                      onChange={(e) => setCodigo(e.target.value)}


                    />
                  </GridItem>
                </GridContainer>


                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="codigo_controle"
                      label="Código Controle"
                      name="codigo_controle"
                      value={codigoControle}
                      onChange={(e) => setCodigoControle(e.target.value)}

                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="descrição"
                      label="Descrição"
                      name="descricao"
                      value={descricao}
                      onChange={(e) => setDescricao(e.target.value)}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Selection resource="dados/tipopagante" marginTop={20} marginBottom={8} label="Quem paga o condomínio" handleChangeValue={(val) => { setCondominioQuempg(val); }} value={condominioQuempg} />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Selection resource="administradora" marginTop={20} marginBottom={8} label="Administradora" handleChangeValue={(val) => { setAdministradora(val); }} value={administradora} />
                  </GridItem>
                </GridContainer>


                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Email *"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </GridItem>
                </GridContainer>


                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="login"
                      label="Login"
                      name="login"
                      value={login}
                      onChange={(e) => setLogin(e.target.value)}
                    />
                  </GridItem>
                </GridContainer>


                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="senha"
                      label="Senha"
                      name="senha"
                      value={senha}
                      onChange={(e) => setSenha(e.target.value)}
                    />
                  </GridItem>
                </GridContainer>


                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="endereco_boleto"
                      label="Endereço boleto condomínio"
                      name="endereco_boleto"
                      value={enderecoBoleto}
                      onChange={(e) => setEnderecoBoleto(e.target.value)}
                    />
                  </GridItem>
                </GridContainer>



                <GridContainer>

                  <GridItem xs={12} sm={12} md={1} style={{ marginTop: 16 }}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="select">Tipo</InputLabel>

                      <Select
                        labelId={tipoDocCondominio}
                        label={tipoDocCondominio}
                        variant="outlined"
                        name={tipoDocCondominio}
                        fullWidth
                        value={tipoDocCondominio ? tipoDocCondominio : 'CPF'}
                        onChange={(e) => setTipoDocCondominio(e.target.value)}
                      >
                        <MenuItem key="CPF" value="CPF">CPF</MenuItem>
                        <MenuItem key="CNPJ" value="CNPJ">CNPJ</MenuItem>

                      </Select>
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={11}>
                    <InputMask mask={tipoDocCondominio === 'CPF' ? '999.999.999-99' : '99.999.999/9999-99'} value={cpfCnpjSacadoCondominio} onChange={(e) => setCpfCnpjSacadoCondominio(e.target.value)}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="cpfcnpj_sacado_condominio"
                        label="CPF/CNPJ sacado condomínio"
                        name="cpfcnpj_sacado_condoiminio"
                      />
                    </InputMask>
                  </GridItem>
                </GridContainer>


                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Selection resource="dados/tipopagante" marginTop={20} marginBottom={8} label="Quem paga o iptu" handleChangeValue={(val) => { setIptuQuempg(val); }} value={iptuQuempg} />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Selection resource="dados/formapg" marginTop={20} marginBottom={8} label="Forma Pagamento iptu" handleChangeValue={(val) => { setIptuFormapg(val); }} value={iptuFormapg} />
                  </GridItem>
                </GridContainer>


                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="iptu_inscricao"
                      label="Inscrição IPTU"
                      name="iptu_inscricao"
                      value={iptuInscricao}
                      onChange={(e) => setIptuInscricao(e.target.value)}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={1} style={{ marginTop: 16 }}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="select">Tipo</InputLabel>
                      <Select
                        labelId={tipoDocIptu}
                        label={tipoDocIptu}
                        variant="outlined"
                        name={tipoDocIptu}
                        fullWidth
                        value={tipoDocIptu ? tipoDocIptu : 'CPF'}
                        onChange={(e) => setTipoDocIptu(e.target.value)}
                      >
                        <MenuItem key="CPF" value="CPF">CPF</MenuItem>
                        <MenuItem key="CNPJ" value="CNPJ">CNPJ</MenuItem>

                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={11}>

                    <InputMask mask={tipoDocIptu === 'CPF' ? '999.999.999-99' : '99.999.999/9999-99'} value={cpfCnpjSacadoIptu} onChange={(e) => setCpfCnpjSacadoIptu(e.target.value)}>

                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="cpf_sacado_iptu"
                        label="CPF/CNPJ sacado IPTU"
                        name="cpfcnpj_sacado_iptu"
                      />

                    </InputMask>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="funesbom_inscricao"
                      label="Inscrição FUNESBOM"
                      name="funesbom_inscricao"
                      value={funesbomInscricao}
                      onChange={(e) => setFunesbomInscricao(e.target.value)}
                    />
                  </GridItem>
                </GridContainer>




              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">Salvar</Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>

      </GridContainer>
    </div>
  );
}
