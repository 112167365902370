import React, { useState, useEffect, useCallback } from 'react';
import { useToasts } from 'react-toast-notifications'
import moment from 'moment';
import ptBrLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from '@material-ui/core/styles';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Table from 'components/Table/Table.js';
import { CircularProgress } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import Box from '@material-ui/core/Box';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import api from '../../services/api';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import id from 'date-fns/esm/locale/id/index.js';

const useStyles = makeStyles(styles);

export default function Auditoria() {
  const classes = useStyles();

  const [isLoading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date('2021-08-05'));
  const [data, setData] = useState([]);
  const [tipoDoc, setTipoDoc] = useState('4e1a3dc0-2b9b-46a9-926b-99c93fe99070');
  const { addToast } = useToasts();

  const getData = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.post("/imovel/search", {
        filtros: {
          selectedDate,
          auditoria: true
        }
      });
      const despesas = response.data.despesas;      
      const imoveis = [];

      let tipoDocumento = '';
      
      if (tipoDoc === "4e1a3dc0-2b9b-46a9-926b-99c93fe99070"){
        tipoDocumento = "CONDOMINIO";
      }
      if (tipoDoc === "18f2e658-e283-4225-8e98-069fc63d7b65"){
        tipoDocumento = "IPTU";
      }
      if (tipoDoc === "f3af1d9a-3d6a-420e-892f-2199f75279da"){
        tipoDocumento = "FUNESBOM";
      }
      
      response.data.imoveis.map(i => {
        
        let enviosistema = i.despesacontrole[0] && i.despesacontrole[0].enviado_sistema ? 'S' : 'N';
        if (i.despesacontrole[0] && i.despesacontrole[0].status_envio === 'P'){
           enviosistema = 'P';
        }

        const boleto_capturado = despesas.find(d => d.imovel.id === i.id && d.tipo === tipoDocumento);
        const pagamento = boleto_capturado && boleto_capturado.data_pagamento ? 'S' : 'N';
        const comprovante = boleto_capturado && boleto_capturado.envio_comprovante ? 'S' : 'N';

        imoveis.push({
          id: i.id,
          codigo: i.codigo,
          quempaga: i.condominio_quempg.nome,
          boleto_capturado: boleto_capturado ? 'S' : 'N',
          despesa_lancada: i.despesacontrole[0] && i.despesacontrole[0].enviado_sistema ? 'S' : 'N',
          cotas: '-x-',
          conferido: i.despesacontrole[0] && i.despesacontrole[0].conferido ? 'S' : 'N',
          pagamento,
          comprovante,
          enviosistema         
        });

      })

      setData(imoveis);
      setLoading(false);
    }
    catch (e) {
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });
      setLoading(false);
    }
  }, [selectedDate, tipoDoc]);

  useEffect(() => {
    getData();
  }, [selectedDate, tipoDoc]);


  return (
    <div>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Resumo, Conferência, Auditoria / Envio - {moment(selectedDate).format("M/Y")}</h4>
            </CardHeader>
            <CardBody>

              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
                <DatePicker
                  views={["year", "month"]}
                  label="Selecione Mes / Ano"
                  value={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                />
              </MuiPickersUtilsProvider>
              <GridContainer>
                 <GridItem xs={12} sm={12} md={6} style={{ marginTop: 16 }}>
                    <FormControl fullWidth variant="outlined">     
                    <InputLabel id="select">Tipo Documento</InputLabel>
                
                      <Select
                        labelId={"Tipo Documento"}
                        label={"Tipo Documento"}
                        variant="outlined"
                        name={"Tipo Documento"}
                        fullWidth
                        value={tipoDoc}
                        onChange={(e) => setTipoDoc(e.target.value)}
                      >
                        <MenuItem key="CONDOMINIO" value="4e1a3dc0-2b9b-46a9-926b-99c93fe99070">CONDOMINIO</MenuItem>
                        <MenuItem key="IPTU" value="18f2e658-e283-4225-8e98-069fc63d7b65">IPTU</MenuItem>
                        <MenuItem key="FUNESBOM" value="f3af1d9a-3d6a-420e-892f-2199f75279da">FUNESBOM</MenuItem>

                      </Select>
                    </FormControl>
                  </GridItem>
                  </GridContainer>

              {data && data.length > 0 ? <Table
                tableHeaderColor="primary"
                tableHead={['Imóvel', 'Quem paga', 'Boleto capturado', 'Conferido', 'Envio Sistema para InGaia', 'Pagamento', 'Envio comprovante']}
                tableColumn={['codigo', 'quempaga', 'boleto_capturado',  'conferido', 'enviosistema','pagamento', 'comprovante']}
                tableColumnSize={['10%', '15%', '10%', '10%', '10%', '10%', '10%', '10%']}
                tableData={data}
                resource="imovel"
                onDelete={(id) => handleDelete(id)}

              /> : isLoading ? (
                <Box mt={6}>
                  <CircularProgress />
                </Box>
              ) : (
                <p style={{ marginTop: 20 }}>Nenhum imovel cadastrada no momento.</p>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

    </div>
  );
}
