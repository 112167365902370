import 'rsuite/dist/rsuite.min.css';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Uploader } from 'rsuite';
import { setCookie, parseCookies } from 'nookies'
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from "date-fns/locale/pt-BR";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';


import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';
import Selection from 'components/Selection/Selection.js';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function Arquivos() {

  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();
  const [processado, setProcessado] = useState('T');
  const [data, setData] = useState([]);
  const [tipoArquivo, setTipoArquivo] = useState();
  const [possuiErro, setPossuiErro] = useState(false);
  const [selecionados, setSelecionados] = useState([]);
  const [tipo, setTipo] = useState();
  const [dataInicial, setDataInicial] = useState(new Date(new Date() - (30 * 86400000)));
  const [dataFinal, setDataFinal] = useState(new Date());

  const { addToast } = useToasts()
  const cookies = parseCookies();

  const upload = useRef();
  let totalCount = 0;

  const handleDataInicialChange = (date) => {
    setDataInicial(date);
  };

  const handleDataFinalChange = (date) => {
    setDataFinal(date);
  };

  const getData = useCallback(async () => {

    try {

      setLoading(true);

      const response = await api.post("/arquivos/search", {
        filtros: {
          processado,
          dataInicial,
          dataFinal,
          tipo
        },
      });

      const dados = [];

      if (response.data) {
        response.data.map(d => {

          if (d.processado === 'E') {
            setPossuiErro(true);
          }

          dados.push({
            id: d.id,
            nome: d.nome,
            processado: d.processado,
            created_at: new Date(d.created_at).toLocaleDateString('pt-br', {
              day: '2-digit',
              month: 'long',
              year: 'numeric'
            }),
            tipo: d.arquivotipo?.nome,
            erro: d.erro ? d.erro : '-x-'
          })
        })
      }

      setData(dados);
      setLoading(false);

    }
    catch (e) {

      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });
      setLoading(false);

    }

  }, [processado, dataInicial, dataFinal, tipo, selecionados]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não',
        }
      ]
    });

  }, []);

  const postDeleteAction = useCallback(async (id) => {

    try {
      await api.delete(`/arquivos/${id}`);
      setData(data => data.filter(d => d.id != id));
    }
    catch (e) {
      addToast(e.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }

  }, []);

  const handleSelect = useCallback(async (id) => {
    let data = [...selecionados];
    if (data.find(s => s === id)) {
      data = data.filter(s => s !== id);
    }
    else {
      data.push(id);
    }
    setSelecionados(data);

  }, [selecionados]);

  const reprocess = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.post("/arquivos/reprocess", {
        selecionados
      });
      setSelecionados([]);
      getData();

    }
    catch (e) {
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });
      setLoading(false);
    }
  }, [selecionados]);


  const processData = useCallback(async () => {
    try {
      setLoading(true);
      await api.get("/process", {
        
      });
      getData();    
    }
    catch (e) {
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });
      setLoading(false);
    }
  }, []);

  const handleUploadCount = useCallback(async (total, action) => {
    if (action === 'minus') {
      totalCount--;
    }
    else {
      totalCount = total;
    }

    if (totalCount <= 0) {
      getData();
    }

  }, []);

  useEffect(() => {

    const interval = setInterval(() => {
      getData();
    }, 50000);

    getData();

    return () => clearInterval(interval);

  }, []);


  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Envio de Arquivos</h4>
            <p className={classes.cardCategoryWhite}>
              Lista de arquivos enviados.
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer>

              <GridItem xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
                <Selection resource="dados/tipoarquivo" marginTop={0} marginBottom={15} label="Selecione o tipo arquivo" handleChangeValue={(val) => { setTipo(val); }} value={tipo} />
              </GridItem>


              <GridItem xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="select">Processado</InputLabel>
                  <Select
                    labelId={processado}
                    label="Processado"
                    variant="outlined"
                    name={processado}
                    fullWidth
                    value={processado ? processado : 'N'}
                    onChange={(e) => setProcessado(e.target.value)}
                  >
                    <MenuItem key="T" value="T">TODOS</MenuItem>
                    <MenuItem key="N" value="N">NÃO</MenuItem>
                    <MenuItem key="S" value="S">SIM</MenuItem>
                    <MenuItem key="E" value="E">ERRO</MenuItem>

                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
              <DatePicker style={{ marginTop: 25 }} label="Data inicial" value={dataInicial} onChange={handleDataInicialChange} format="dd/MM/yyyy" inputVariant="outlined" fullWidth />
            </MuiPickersUtilsProvider>

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
              <DatePicker style={{ marginTop: 25 }} label="Data final" value={dataFinal} onChange={handleDataFinalChange} format="dd/MM/yyyy" inputVariant="outlined" fullWidth />
            </MuiPickersUtilsProvider>

            <Button color="primary" type="button" style={{ marginTop: 20 }} onClick={getData}>Filtrar</Button>

            <hr />
            <h4 style={{ backgroundColor: '#FEE', padding: '10px' }}>Envio de arquivos</h4>
            <hr />

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Selection resource="dados/tipoarquivo" marginTop={0} marginBottom={15} label="Selecione o tipo arquivo" handleChangeValue={(val) => { setTipoArquivo(val); }} value={tipoArquivo} />
              </GridItem>
            </GridContainer>

            {tipoArquivo && !isLoading && <Uploader
            
              action={`${process.env.REACT_APP_API_URL}/arquivos`}
              data={{ tipoArquivo }}
              headers={{ authorization: `Bearer ${cookies['kariocaimoveis.token']}` }}
              name="arquivos"
              onChange={(fileList) => {
                const files = fileList.filter(x => x.status === 'inited');
                handleUploadCount(Number(files.length), 'add');
              }}
              accept=".pdf"
              fileListVisible={true}
              ref={upload}
              onSuccess={() => handleUploadCount(1, 'minus')}
              multiple={true}
              draggable>
              <div style={{ textAlign: 'center', lineHeight: 8, backgroundColor: '#CACACA' }}>Clique aqui ou arraste os arquivos.</div>
            </Uploader>}

            <hr />
            <h4 style={{ backgroundColor: '#FEE', padding: '10px' }}>Arquivos enviados</h4>
            <hr />


             {isLoading ? <><Box mt={6}>
                <CircularProgress />
              </Box></> : <Button color="secondary" type="button" style={{ marginTop: 20 }} onClick={processData}>Processar arquivos pendentes</Button>}



            {data && data.length > 0 ? <Table
              tableHeaderColor="primary"
              tableHead={['', 'Tipo', 'Nome', 'Processado', 'Criado em', 'Erro', '']}
              tableColumn={['reprocess', 'tipo', 'nome', 'processado', 'created_at', 'erro', 'delete']}
              tableColumnSize={['5%', '20%', '15%', '10%', '20%', '20%', '1%']}
              tableData={data}
              resource="administradora"
              handleSelect={(id) => handleSelect(id)}
              onDelete={(id) => handleDelete(id)}
              selected={selecionados}

            /> : isLoading ? (
              <Box mt={6}>
                <CircularProgress />
              </Box>
            ) : (
              <p style={{ marginTop: 20 }}>Nenhum arquivo registrado.</p>
            )}

       
            {possuiErro && <Button color="primary" type="button" style={{ marginTop: 20 }} onClick={reprocess}>Re-processar selecionados com erro</Button>}



          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
