import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function Administradora() {

  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  const [data, setData] = useState([]);
  const { addToast } = useToasts()

  useEffect(() => {
    getData();
  }, []);


  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get("/administradora");
      setData(response.data);
      setLoading(false);
    }
    catch (e) {
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });
      setLoading(false);
    }
  }, []);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não',
        }
      ]
    });

  }, []);

  const handleImport = useCallback(async () => {
    try {
      setLoading(true);
      await api.get(`/import/administradoras`);
      addToast('Atualizado com sucesso.', {
        appearance: 'success',
        autoDismiss: true,
      });
      
      getData();
      
    }
    catch (e) {
      addToast('Ocorreu um erro. Tente novamente mais tarde.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, []);

  const postDeleteAction = useCallback(async (id) => {
    try {
      await api.delete(`/administradora/${id}`);
      setData(data => data.filter(d => d.id != id));
    }
    catch (e) {
      addToast(e.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, []);


  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Administradoras</h4>
            <p className={classes.cardCategoryWhite}>
              Lista de administradoras cadastradas.
            </p>
          </CardHeader>
          <CardBody>

           {isLoading ? <Box mt={6} mb={6}>
                <CircularProgress />
              </Box> : <Button color="warning" onClick={() => handleImport()}>Importar do Super Lógica</Button>}
          
            {data && data.length > 0 ? <Table
              tableHeaderColor="primary"
              tableHead={['Nome', '', '']}
              tableColumn={['nome', 'edit', 'delete']}
              tableColumnSize={['80%', '1%', '1%']}
              tableData={data}
              resource="administradora"
              onDelete={(id) => handleDelete(id)}

            /> : isLoading ? (
              <Box mt={6}>
                <CircularProgress />
              </Box>
            ) : (
              <p style={{ marginTop: 20 }}>Nenhuma administradora cadastrada no momento.</p>
            )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
