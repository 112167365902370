import React, { useState, useEffect, useCallback } from 'react';
import { useToasts } from 'react-toast-notifications'
import moment from 'moment';
import ptBrLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from '@material-ui/core/styles';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Table from 'components/Table/Table.js';
import Button from 'components/CustomButtons/Button.js';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import api from '../../services/api';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

const useStyles = makeStyles(styles);

export default function Despesas() {
  const classes = useStyles();

  const [isLoading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date('2021-08-05'));
  const [selecionados, setSelecionados] = useState([]);
  const [data, setData] = useState();
  const { addToast } = useToasts();

  const getData = useCallback(async () => {
    try {

      setLoading(true);
      const response = await api.post("/imovel/despesa", {
        referencia: selectedDate
      });

      const imoveis = [];

      const listImoveis = response.data.imoveis;
      const despesas = response.data.despesas;
      const despesasLancamento = response.data.despesasLancamento;


      listImoveis.map(i => {

        let enviosistema = i.despesacontrole[0] && i.despesacontrole[0].enviado_sistema ? 'S' : 'N';
        if (i.despesacontrole[0] && i.despesacontrole[0].status_envio === 'P'){
           enviosistema = 'P';
        }


        const despesa = despesas.find(d => d.imovel.id === i.id && d.tipo === 'CONDOMINIO');
        const despesaIptu = despesas.find(d => d.imovel.id === i.id && d.tipo === 'IPTU');
        const despesaFunesbom = despesas.find(d => d.imovel.id === i.id && d.tipo === 'FUNESBOM');

        const condominios = despesa ? despesasLancamento.filter(dl => dl.despesa.id === despesa.id && dl.tipodespesa === 'CONDOMINIO') : null;
        const condominio = despesa ? despesasLancamento.find(dl => dl.despesa.id === despesa.id && dl.tipodespesa === 'CONDOMINIO') : null;
        const cotas = despesa ? despesasLancamento.filter(dl => dl.despesa.id === despesa.id && dl.tipodespesa === 'COTASEXTRAS') : null;
        const descontos = despesa ? despesasLancamento.filter(dl => dl.despesa.id === despesa.id && dl.tipodespesa === 'DESCONTO') : null;
        const iptu = despesaIptu ? despesasLancamento.find(dl => dl.despesa.id === despesaIptu.id && dl.tipodespesa === 'IPTU') : null;
        const funesbom = despesaFunesbom ? despesasLancamento.find(dl => dl.despesa.id === despesaFunesbom.id && dl.tipodespesa === 'FUNESBOM') : null;

        let cotasValor = '-x-';
        let descontosValor = '-x-';
        let condominioValor = '-x-';

        if (condominios) {
          condominioValor = condominios.reduce((prevValue, cond) => prevValue + Number(cond.valor), 0);
          condominioValor = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(condominioValor));
        }

        if (cotas) {
          cotasValor = cotas.reduce((prevValue, cota) => prevValue + Number(cota.valor), 0);
          cotasValor = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(cotasValor));
        }

        if (descontos) {
          descontosValor = descontos.reduce((prevValue, desconto) => prevValue + Number(desconto.valor), 0);
          descontosValor = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(descontosValor))
        }

        imoveis.push({
          id: i.id,
          codigo: i.codigo,
          boletocondominio: despesa ? new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(despesa.valor)) : '-x-',
          desconto: descontosValor,
          condominio: condominioValor,
          iptu: iptu ? new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(iptu.valor)) : '-x-',
          cotas: cotasValor,
          funesbom: funesbom ? new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(funesbom.valor)) : '-x-',
          laudemio: '-x-',
          lancado_sistema: enviosistema,
          conferido: i.despesacontrole[0] && i.despesacontrole[0].conferido ? 'S' : 'N',
          enviado: i.despesacontrole[0] && i.despesacontrole[0].envio_comprovante ? 'S' : 'N',
        })
      })

      setData(imoveis);    

    }
    catch (e) {
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });
     }
    finally{
      setLoading(false);
    }
  }, [selectedDate]);


  const handleSelect = useCallback(async (id) => {
    let data = [...selecionados];
    if (data.find(s => s === id)) {
      data = data.filter(s => s !== id);
    }
    else {
      data.push(id);
    }
    setSelecionados(data);
  }, [selecionados]);

  const handleSetConferido = useCallback(async (conferido) => {
    try {     
      await api.post("/despesas/conferido", {
        imoveis: selecionados,
        conferido,
        referencia: selectedDate
      });
      getData();
      setSelecionados([]);
    }
    catch {
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });     
    }
  }, [selecionados, selectedDate]);

  const handleSetEnviarSistema = useCallback(async () => {
    try {
     
      await api.post("/despesas/enviarsistema", {
        imoveis: selecionados,
        enviado_sistema: true,
        referencia: selectedDate,
        status_envio: 'P'
      });
      getData();
      setSelecionados([]);
    }
    catch {
      
    }

  }, [selecionados, selectedDate]);

  useEffect(() => {
    if (selectedDate) {
      getData();
    }
  }, [selectedDate]);


  return (
    <div>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Despesas Resumo - {moment(selectedDate).format("M/Y")}</h4>
            </CardHeader>
            <CardBody>

              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
                <DatePicker
                  views={["year", "month"]}
                  label="Selecione Mes / Ano"
                  value={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                />
              </MuiPickersUtilsProvider>

              {data && data.length > 0 ?
                <>
                <br />  
                            
                {!isLoading ? 
                <>
                <Button color="danger" type="button" style={{ marginTop: 20 }} onClick={() => handleSetConferido(false)} >Desmarcar conferidos</Button>
                <Button color="success" type="button" style={{ marginTop: 20 }} onClick={() => handleSetConferido(true)}>Marcar como conferidos</Button>
                <Button color="warning" type="button" style={{ marginTop: 20 }} onClick={() => handleSetEnviarSistema()}>Enviar selecionados para o sistema</Button>          
 
                <Table
                  tableHeaderColor="primary"
                  tableHead={['', 'Imóvel', 'Boleto Condominio', 'Outras despesas', 'Desconto', 'Condominio', 'Iptu', 'Funesbom', 'Conferido', 'Enviado Sistema']}
                  tableColumn={['check', 'codigo', 'boletocondominio', 'cotas', 'desconto', 'condominio', 'iptu', 'funesbom','conferido', 'lancado_sistema']}
                  tableColumnSize={['5%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%']}
                  tableData={data}
                  resource="imovel"
                  onDelete={(id) => handleDelete(id)}
                  handleSelect={(id) => handleSelect(id)}
                  selected={selecionados}
                /> 
                <Button color="danger" type="button" style={{ marginTop: 20 }} onClick={() => handleSetConferido(false)} >Desmarcar conferidos</Button>
                <Button color="success" type="button" style={{ marginTop: 20 }} onClick={() => handleSetConferido(true)}>Marcar como conferidos</Button>
                <Button color="warning" type="button" style={{ marginTop: 20 }} onClick={() => handleSetEnviarSistema()}>Enviar selecionados para o sistema</Button>
                </>
                :
                   <Box mt={6}>
                    <CircularProgress />
                  </Box>
                }
                           
               
                </> : isLoading ? (
                  <Box mt={6}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <p style={{ marginTop: 20 }}>Nenhuma despesa cadastrada no momento.</p>
                )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

    </div>
  );
}
