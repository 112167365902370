import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import TextField from '@material-ui/core/TextField';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';
import Selection from 'components/Selection/Selection.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function Imovel() {

  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  const [codigo, setCodigo] = useState();
  const [administradora, setAdministradora] = useState();
  const [data, setData] = useState([]);
  const { addToast } = useToasts()

  useEffect(() => {
    getData();
  }, []);


  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.post("/imovel/search", {
        filtros: {
          codigo,
          administradora
        }
      });
      const imoveis = [];
      response.data.map(i => {


        imoveis.push({
          id: i.id,
          codigo: i.codigo,
          descricao: i.descricao,
          administradora: i.administradora?.nome,
          endereco_boleto: i.endereco_boleto
        })
      })


      setData(imoveis);
      setLoading(false);
    }
    catch (e) {
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });
      setLoading(false);
    }
  }, [codigo, administradora]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não',
        }
      ]
    });

  }, []);

  const postDeleteAction = useCallback(async (id) => {
    try {
      await api.delete(`/imovel/${id}`);
      setData(data => data.filter(d => d.id != id));
    }
    catch (e) {
      addToast(e.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, []);


  const handleImport = useCallback(async () => {
    try {
      setLoading(true);
      await api.get(`/import/imoveis`);
      addToast('Atualizado com sucesso.', {
        appearance: 'success',
        autoDismiss: true,
      });
      
      getData();
      
    }
    catch (e) {
      addToast('Ocorreu um erro. Tente novamente mais tarde.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, []);


  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Imoveis</h4>
            <p className={classes.cardCategoryWhite}>
              Lista de imoveis cadastrados.
            </p>
          </CardHeader>
          <CardBody>


           {isLoading ? <Box mt={6} mb={6}>
                <CircularProgress />
              </Box> : <Button color="warning" onClick={() => handleImport()}>Importar do Super Lógica</Button>}
          


            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="name"
              label="Pesquise pelo código"
              name="id"
              value={codigo}
              onChange={(e) => setCodigo(e.target.value)}
            />


            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Selection resource="administradora" marginTop={20} marginBottom={8} label="Administradora" handleChangeValue={(val) => { setAdministradora(val); }} value={administradora} />
              </GridItem>
            </GridContainer>

            <Button color="primary" type="button" style={{ marginTop: 20 }} onClick={getData}>Filtrar</Button>

            <br /><br />

            <Link to="/admin/imovel/new"> <Button color="info">Adicionar novo imovel</Button></Link>

            {data && data.length > 0 ? <Table
              tableHeaderColor="primary"
              tableHead={['Codigo', 'Descrição', 'Administradora', 'Endereco boleto', '', '']}
              tableColumn={['codigo', 'descricao', 'administradora', 'endereco_boleto', 'edit', 'delete']}
              tableColumnSize={['15%', '30%', '10%', '30%', '1%', '1%']}
              tableData={data}
              resource="imovel"
              onDelete={(id) => handleDelete(id)}

            /> : isLoading ? (
              <Box mt={6}>
                <CircularProgress />
              </Box>
            ) : (
              <p style={{ marginTop: 20 }}>Nenhum imovel cadastrada no momento.</p>
            )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
