import React, { useState, useEffect, useCallback } from 'react';
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import ptBrLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from '@material-ui/core/styles';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Table from 'components/Table/Table.js';
import Button from 'components/CustomButtons/Button.js';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';


import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import api from '../../services/api';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

const useStyles = makeStyles(styles);

export default function DespesasLancadas() {
  const classes = useStyles();

  const [isLoading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date('2021-08-05'));
  const [tipodespesa, setTipoDespesa] = useState('T');
  const [codigo, setCodigo] = useState();
  const [data, setData] = useState();
  const { addToast } = useToasts();

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.post("/imovel/despesa/search", {
        referencia: selectedDate,
        tipodespesa,
        codigo
      });

      const despesas = [];

      const despImoveis = response.data.despesasLancamento;

      despImoveis.map(d => {
        despesas.push({
          id: d.id,
          codigo: d.despesa.imovel.codigo,
          descritivo: d.descricao,
          valor: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(d.valor)),
          tipodespesa: d.tipodespesa
        })
      })

      setData(despesas);

      setLoading(false);
    }
    catch (e) {

      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });
      setLoading(false);
    }
  }, [selectedDate, tipodespesa, codigo]);


  const handleDelete = useCallback(async (id) => {


    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não',
        }
      ]
    });

  }, []);

  const postDeleteAction = useCallback(async (id) => {
    try {
      await api.delete(`/imovel/despesa/lancamento/${id}`);
      setData(data => data.filter(d => d.id != id));
    }
    catch (e) {
      addToast(e.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, []);


  useEffect(() => {
    if (selectedDate) {
      getData();
    }
  }, [selectedDate]);


  return (
    <div>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Despesas Lancadas - {moment(selectedDate).format("M/Y")}</h4>
            </CardHeader>
            <CardBody>


              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
                <DatePicker
                  views={["year", "month"]}
                  label="Selecione Mes / Ano"
                  value={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                />
              </MuiPickersUtilsProvider>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: 25 }}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="select">Tipo despesa</InputLabel>
                    <Select
                      labelId={tipodespesa}
                      label="Tipo Despesa"
                      variant="outlined"
                      name={tipodespesa}
                      fullWidth
                      value={tipodespesa ? tipodespesa : 'T'}
                      onChange={(e) => setTipoDespesa(e.target.value)}
                    >
                      <MenuItem key="T" value="T">TODAS</MenuItem>
                      <MenuItem key="CONDOMINIO" value="CONDOMINIO">CONDOMINIO</MenuItem>
                      <MenuItem key="COTASEXTRAS" value="COTASEXTRAS">OUTRAS DESPESAS</MenuItem>
                      <MenuItem key="DESCONTO" value="DESCONTO">DESCONTOS</MenuItem>
                      <MenuItem key="IPTU" value="IPTU">IPTU</MenuItem>
                      <MenuItem key="FUNESBOM" value="FUNESBOM">FUNESBOM</MenuItem>


                    </Select>
                  </FormControl>
                </GridItem>


                <GridItem xs={12} sm={12} md={12} style={{ marginTop: 5 }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="name"
                    label="Pesquise pelo código"
                    name="id"
                    value={codigo}
                    onChange={(e) => setCodigo(e.target.value)}
                  />
                </GridItem>

              </GridContainer>

              <Button color="primary" type="button" style={{ marginTop: 20 }} onClick={getData}>Filtrar</Button>


              {data && data.length > 0 ?
                <><Table
                  tableHeaderColor="primary"
                  tableHead={['Imóvel Código', 'Descritivo', 'Valor', 'Tipo Despesa', '']}
                  tableColumn={['codigo', 'descritivo', 'valor', 'tipodespesa', 'delete']}
                  tableColumnSize={['10%', '30%', '10%', '10%', '5%']}
                  tableData={data}
                  resource="imovel"
                  onDelete={(id) => handleDelete(id)}
                  selected={[]}

                />

                </> : isLoading ? (
                  <Box mt={6}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <p style={{ marginTop: 20 }}>Nenhuma despesa cadastrada no momento.</p>
                )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

    </div>
  );
}
