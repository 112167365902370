/*eslint-disable*/
import React, { useEffect, useCallback, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function Selection({ resource, marginTop, marginBottom, label, handleChangeValue, value }) {

  useEffect(() => {
    loadData();
  }, []);

  const [opcoes, setOpcoes] = useState([]);


  const loadData = useCallback(async () => {
    try {

      const response = await api.get(`/${resource}`);
      setOpcoes(response.data);

    }
    catch (e) {

    }
  }, []);

  const classes = useStyles();

  return (
    <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop, marginBottom }}>
      <InputLabel id="select">{label}</InputLabel>

      <Select
        labelId={label}
        label={label}
        variant="outlined"
        name={label}
        fullWidth
        value={value ? value : ''}
        onChange={(e) => handleChangeValue(e.target.value)}
      >
        <MenuItem key="" value="">Selecione</MenuItem>
        {opcoes.map(data => {
          return <MenuItem key={data.id} value={data.id}>{data.nome}</MenuItem>
        })}
      </Select>
    </FormControl>
  );
}
